import { render, staticRenderFns } from "./HelpOverview.vue?vue&type=template&id=8359e8d0&scoped=true"
import script from "./HelpOverview.vue?vue&type=script&lang=js"
export * from "./HelpOverview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8359e8d0",
  null
  
)

export default component.exports