<template>
  <div class="overview-wrapper">
    <div class="side-wrapper">
      <ul class="list-group list-group-flush">
        <a
          v-for="(col, i) in searchOptions"
          :key="i"
          href="#"
          class="list-group-item list-group-item-action flex-column align-items-start side-item"
          @click="sideColClicked(i)"
        >
          {{ col.name }}
        </a>
      </ul>
    </div>
    <div class="content-wrapper">
      <b-breadcrumb :items="crumb_links"></b-breadcrumb>
      <dl class="results">
        <p>
          This section of TAIR's website contains links to documents to help you
          maximally use TAIRs database and tools.
        </p>
        <p>
          Use the Definitions help. Wherever you see a
          <b-icon class="ml-2" icon="question-circle-fill"></b-icon> symbol next
          to a word , click on the symbol to see a definition for the word or
          phrase.
        </p>
        <p>
          If you have a specific topic you need help with, you can search ONLY
          within the help section of TAIR using the search box below.
        </p>

        <div class="options-panel">
          <div
            v-for="(option, i) in searchOptions"
            :key="i"
            class="search-option"
          >
            <h3 class="pg-link" @click="sideColClicked(i)">
              {{ option.name }}
            </h3>
            <p>{{ option.description }}</p>
          </div>
        </div>
        <div class="flex text-left mt-2">
          <dt>Configuring your browser to use TAIR.</dt>
          <dd>
            This site makes extensive use of Java and Javascript. If you don't
            see a toolbar and the tair logo on the top of the page, make sure
            that both Java and Javascript are enabled in your browser.
            Registration, ordering and a variety of other processes requires you
            to enable cookies. Please update your browser configuration to
            accept cookies for the site.
          </dd>
          <ul>
            <li>
              To enable Javascript in Netscape, go to the Edit Menu, choose
              Preferences, click on 'Advanced', check both Java and Javascript
              boxes.
            </li>
            <li>
              To enable Javascript in Internet Explorer, go to the Explorer
              menu, choose Preferences. Under Web Content/Active Scripting,
              choose "enable scripting".
            </li>
            <li>
              To accept Cookies, in Internet Explorer, go to the Explorer menu,
              choose Preferences. Under Receiving Files , choose Cookies. You
              can opt to accept all cookies, cookies per site or ask for each
              cookie.
            </li>
          </ul>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpOverview",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Help",
          active: true,
        },
      ],
      overviewTitle: "HELP",
      description:
        "This section of TAIR's website contains links to documents to help you maximally use TAIRs database and tools.",
      searchOptions: [
        {
          name: "YouTube Tutorials",
          route: "https://www.youtube.com/channel/UCqYlBTnoVz6s1beB058lBqQ",
          description: "TAIR's You Tube Channel with video tutorials",
        },
        {
          name: "FAQ",
          route: "https://conf.phoenixbioinformatics.org/display/COM/FAQ",
          description:
            "A list of frequently asked questions ordered by major topic.",
        },
        {
          name: "Quick Start",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Quick+Start",
          description: "A brief introduction to common uses of TAIR.",
        },
        {
          name: "Database and Tool Help Pages",
          route: `https://conf.phoenixbioinformatics.org/display/COM/TAIR+Database+and+Tool+Help`,
          description:
            "Help pages for searching TAIR database and using the analysis tools.",
        },
        {
          name: "TAIR Glossary",
          route: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/processor?type=definition&update_action=glossary`,
          description: "A glossary of terms used in TAIR database.",
        },
      ],
    };
  },
  mounted() {
    document.title = "TAIR Help";
  },
  methods: {
    sideColClicked(index) {
      window.open(this.searchOptions[index].route, "_blank");
    },
  },
};
</script>

<style scoped lang="scss"></style>
